import cn from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import LazyLoad from 'react-lazy-load'
import config from '../../config.json'

export const isVideoString = (video) => !!video && video.includes('video:')

export const videoFrom = (maybeVideo) =>
    isVideoString(maybeVideo) ? maybeVideo.replace('video:', '') : null

export const toIPFSVideo = (video) =>
    video.includes('http') ? video : config.ipfs + video

export const VideoElement = ({
    video: videoStr,
    backimg,
    className,
    image,
    timeout = 2000,
    autoPlay = true,
    useGradient = false,
    fetchPriority = 'low',
    lazy = true,
    ...props
}) => {
    const video = toIPFSVideo(videoStr)
    const videoRef = useRef(null)
    const [state, setState] = useState({
        playing: false,
        initial: true,
    })
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        if (state.initial && state.playing && timeout > 0 && video) {
            const timeoutId = setTimeout(pauseVideo, timeout)
            return () => clearTimeout(timeoutId)
        }
    }, [state.playing, state.initial, timeout, video])

    const playVideo = useCallback(() => {
        const element = videoRef.current
        setState({ playing: true, initial: false })
        if (!element) return
        element.play()
    }, [])

    const pauseVideo = useCallback(() => {
        if (timeout > 0) {
            const element = videoRef.current
            if (!element) return
            element.pause()
        }
    }, [])

    const startPlaying = () => {
        setState({ playing: true, initial: state.initial })
        setIsLoaded(true)
    }

    return lazy ? (
        <LazyLoad offset={100} isVisible={false}>
            <div
                className={cn(
                    {
                        'bg-gradient-to-b from-page over-page to-transparent':
                            useGradient,
                    },
                    'flex h-full w-full aspect-1 content-center hover:scale-105',
                )}
            >
                <video
                    loop
                    autoPlay
                    muted
                    playsInline
                    {...props}
                    onMouseLeave={pauseVideo}
                    onMouseEnter={playVideo}
                    onPlay={startPlaying}
                    ref={videoRef}
                    className={cn('m-auto h-auto', className)}
                    src={video + '#t=0.5'}
                    preload="metadata"
                    alt={image}
                />
            </div>
        </LazyLoad>
    ) : (
        <div
            className={cn(
                {
                    'bg-gradient-to-b from-page over-page to-transparent':
                        useGradient,
                },
                'flex h-full w-full aspect-1 content-center hover:scale-105',
            )}
        >
            <video
                loop
                autoPlay
                muted
                playsInline
                {...props}
                onMouseLeave={pauseVideo}
                onMouseEnter={playVideo}
                onPlay={startPlaying}
                ref={videoRef}
                className={cn('m-auto h-auto', className)}
                src={video + '#t=0.5'}
                preload="metadata"
                alt={image}
            />
        </div>
    )
}

export default VideoElement
